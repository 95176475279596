/*  imported from index.less */

#meepshop { /* 直接寫 font-face 不需要額外下載外部資源，節省 network requests. Reference : 標楷體 support https://fonts.googleapis.com/earlyaccess/cwtexkai.css*/
@font-face {
  font-family: 'cwTeXKai';
  font-style: normal;
  font-weight: 500;
  src: url(//fonts.gstatic.com/ea/cwtexkai/v3/cwTeXKai-zhonly.eot);
  src: url(//fonts.gstatic.com/ea/cwtexkai/v3/cwTeXKai-zhonly.eot?#iefix)
      format('embedded-opentype'),
    url(//fonts.gstatic.com/ea/cwtexkai/v3/cwTeXKai-zhonly.woff2)
      format('woff2'),
    url(//fonts.gstatic.com/ea/cwtexkai/v3/cwTeXKai-zhonly.woff) format('woff'),
    url(//fonts.gstatic.com/ea/cwtexkai/v3/cwTeXKai-zhonly.ttf)
      format('truetype');
}

.meepshop-draft-text__index__root {
  width: 100%;
  padding: 15px 5px;
  color: initial;
  font-size: 16px;

  * {
    word-wrap: break-word;
    white-space: pre-wrap;
    hyphens: auto;
    color: inherit;
  }

  p {
    white-space: pre-wrap;
    margin-bottom: 0px;
    color: initial;
  }

  ul,
  ol {
    list-style-position: inside;
    color: initial;
  }

  em,
  strong,
  span,
  a {
    color: inherit;
  }

  u span {
    text-decoration: underline;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 16px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }
}

.meepshop-draft-text__index__module {
  @media screen and (max-width: 767px) {
    span[style='font-size:16px'] {
      font-size: 14.4px !important;
    }

    span[style='font-size:20px'] {
      font-size: 18px !important;
    }

    span[style='font-size:24px'] {
      font-size: 21.6px !important;
    }

    span[style='font-size:28px'] {
      font-size: 22.4px !important;
    }

    span[style='font-size:32px'] {
      font-size: 25.6px !important;
    }

    span[style='font-size:36px'] {
      font-size: 25.2px !important;
    }
  }
}
 }
@font-family: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";@font-family-no-number: "Noto Sans TC", "PingFang TC", "Microsoft JhengHei", "Helvetica Neue", "Helvetica", "source-han-sans-traditional", "Arial", "sans-serif";